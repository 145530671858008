import React from "react";
import clsx from "clsx";
import useTags from "../contexts/TagsWrapper";
import styled from "styled-components";

const Container = styled.div`
  // bottom: 118px;
`;

const Tags = () => {
  const { tag, dispatchTag } = useTags();
  // console.log(tag);

  const tags = [
    { title: "Tous", value: "" },
    { title: "Lieux physiques", value: "physique" },
    { title: "Lieux virtuels", value: "virtuel" },
    { title: "Archive", value: "archive" },
  ];

  return (
    <Container className='fixed w-full bottom-0 -translate-y-full-- px-xs p-sm md:p-md text-_sm md:text-md'>
      <ul className='flex justify-center flex-wrap'>
        {tags.map((li, i) => (
          <li key={i} className='px-1 mb-2 md:px-2 md:mb-0 '>
            <button
              className={clsx(
                "button outline",
                tag === li.value ? "is-active" : ""
              )}
              onClick={() => dispatchTag(tag !== li.value ? li.value : "")}>
              {li.title}
            </button>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Tags;
