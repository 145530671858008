import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import LieuCard from "../components/LieuCard";
import Map from "../components/ui/Map/Index";
// import clsx from "clsx";
import { TagsWrapper } from "../contexts/TagsWrapper";
import Tags from "../components/Tags";

export const query = graphql`
  query LieuxQuery {
    site {
      buildTime
    }
    sanityLieux {
      seo {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }
      title
      lieux: _rawLieux(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const PageLieux = ({ data: { site, sanityLieux } }) => {
  // console.log(data)
  const { seo, lieux } = sanityLieux;
  // console.log(lieux);

  useEffect(() => {
    setTimeout(() => {
      _handleInViewport();
    }, 1000);
  }, []);

  const _handleInViewport = () => {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
      var lazyVideoObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (
                typeof videoSource.tagName === "string" &&
                videoSource.tagName === "SOURCE"
              ) {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove("lazy");
            // console.log(video.target.nextSibling);
            // setTimeout(() => {
            //   video.target.nextSibling.style.opacity = 0;
            // }, 1000);
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  };

  const points = lieux.map((el, i) => {
    return {
      slug: el.slug.current,
      // geoloc: el.geoloc,
      lat: el.geoloc.split(",")[0],
      lng: el.geoloc.split(",")[1],
      title: el.title,
      type: el.type,
      archive: el.archive,
      image: el.images[0],
    };
  });
  // console.log(points);
  return (
    <div className='page'>
      {seo && (
        <Seo
          pageTitle={seo.metaTitle}
          pageDescription={seo.metaDescription}
          pageImage={seo.metaImage?.asset.url}
          template={`template-lieux `}
          page={false}
        />
      )}
      <input type='hidden' name='buildTime' value={site.buildTime} />
      <TagsWrapper>
        <section>
          <div className='grid md:grid-cols-2 min-h-screen-'>
            {lieux.map((item, i) => (
              <LieuCard key={i} input={item} target={"_self"} />
            ))}
          </div>
        </section>

        <section className='map h-screen w-full'>
          <Map input={points} filterable={true} single={false} />
        </section>

        <Tags />
      </TagsWrapper>
    </div>
  );
};

export default PageLieux;
